import { gql } from 'apollo-angular';

//#region Fragments
const LoginUserFragment = gql`
  fragment LoginUserFragment on User {
    id
    firstName
    lastName
    authToken
    email
    emailConfirmed
    roles
    permissions
    phoneNumber
    language {
      id
      isoCode2
    }
    profilePhoto {
      id
      thumbnail
    }
    clubs {
      id
    }
    currentClubId
    currentClub {
      id
      name
      logoImage {
        id
        thumbnail
      }
    }
  }
`;
//#endregion

//#region Mutations
export const LoginUserMutation = gql`
  mutation loginUser($request: LoginRequestInput!) {
    loginUser(request: $request) {
      ...LoginUserFragment
    }
  }
  ${LoginUserFragment}
`;

export const RegisterUserMutation = gql`
  mutation RegisterUser($item: UserInput!) {
    registerUser(item: $item) {
      ...LoginUserFragment
    }
  }
  ${LoginUserFragment}
`;

export const VerifyEmailMutation = gql`
  mutation VerifyEmail($item: VerifyEmailInput!) {
    verifyEmail(item: $item)
  }
`;

export const ResendVerificationEmailMutation = gql`
  mutation ResendVerificationEmail($id: ID!) {
    resendVerificationEmail(id: $id)
  }
`;

export const ChangePasswordMutation = gql`
  mutation ChangePassword($item: ChangePasswordInput!) {
    changePassword(item: $item)
  }
`;

export const RequestResetPasswordMutation = gql`
  mutation RequestResetPassword($username: String!) {
    requestResetPassword(username: $username)
  }
`;

export const ResetPasswordMutation = gql`
  mutation ResetPassword(
    $username: String!
    $token: String!
    $password: String!
  ) {
    resetPassword(username: $username, token: $token, password: $password)
  }
`;

export const SwitchAccountMutation = gql`
  mutation SwitchAccount($clubId: UUID!) {
    switchAccount(clubId: $clubId) {
      ...LoginUserFragment
    }
  }
  ${LoginUserFragment}
`;

export const AcceptInvitationMutation = gql`
  mutation AcceptInvitation($token: String!) {
    acceptInvitation(token: $token) {
      ...LoginUserFragment
    }
  }
  ${LoginUserFragment}
`;

export const RejectInvitationMutation = gql`
  mutation RejectInvitation($token: String!) {
    rejectInvitation(token: $token)
  }
`;

export const SignInWithGoogleMutation = gql`
  mutation SignInGoogle($token: String!) {
    signInGoogle(token: $token) {
      ...LoginUserFragment
    }
  }
  ${LoginUserFragment}
`;

export const SignUpWithGoogleMutation = gql`
  mutation SignUpGoogle($token: String!) {
    signUpGoogle(token: $token) {
      ...LoginUserFragment
    }
  }
  ${LoginUserFragment}
`;

export const SignUpWithAppleIdMutation = gql`
  mutation SignUpApple($token: String!, $firstName: String, $lastName: String) {
    signUpApple(token: $token, firstName: $firstName, lastName: $lastName) {
      ...LoginUserFragment
    }
  }
  ${LoginUserFragment}
`;

export const SignInWithAppleIdMutation = gql`
  mutation SignInApple($token: String!) {
    signInApple(token: $token) {
      ...LoginUserFragment
    }
  }
  ${LoginUserFragment}
`;
// #endregion

// #region Queries
export const ClubInvitationByToken = gql`
  query ClubInvitationByToken($token: String!) {
    clubInvitationByToken(token: $token) {
      id
      firstName
      lastName
      email
    }
  }
`;
// #endregion
