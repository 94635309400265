import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import type { ApolloQueryResult } from '@apollo/client/core';
import { FilterRequestInput, PageRequestInput } from '@gqlSchema';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import {
  catchError,
  defer,
  EMPTY,
  first,
  map,
  Observable,
  OperatorFunction,
  switchMap,
  tap,
} from 'rxjs';
import {
  GqlListResponse,
  GQLOperationArgs,
  GQLOperationNames,
  GqlSingleResponse,
} from '../types/GQLOperationNames.type';
import { PaginatorService } from './paginator.service';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseService<
  ReturnT = any,
  InputT = any,
> extends PaginatorService {
  protected ui = inject(UiService);
  protected apollo = inject(Apollo);
  protected route = inject(ActivatedRoute);

  //QueryRef stores
  private allRefs: Map<string, QueryRef<GqlListResponse<ReturnT>, any>> =
    new Map();
  private oneRefs: Map<string, QueryRef<GqlSingleResponse<ReturnT>, any>> =
    new Map();
  private queryRefs: Map<string, QueryRef<any, any>> = new Map();

  private operationNames: GQLOperationNames = { oneName: '' };
  private operationArguments: GQLOperationArgs = {};

  // Loaders
  public get isItemLoading() {
    return this._itemLoading;
  }
  private _itemLoading = false;

  //Fragments
  protected abstract selectOneFields: DocumentNode;
  protected abstract selectAllFields: DocumentNode;
  protected abstract deleteRestoreFields: DocumentNode;
  protected selectAllListData?: DocumentNode;

  //Operations
  protected selectOneQuery!: DocumentNode;
  protected selectAllQuery!: DocumentNode;
  protected createMutation!: DocumentNode;
  protected updateMutation!: DocumentNode;
  protected deleteMutation!: DocumentNode;
  protected restoreMutation!: DocumentNode;
  protected allListDataQuery?: DocumentNode;

  //Info messages for operations
  protected updatedMsg = 'ItemUpdated';
  protected createdMsg = 'ItemCreated';
  protected deletedMsg = 'ItemDeleted';
  protected restoredMsg = 'ItemRestored';

  protected maxLimit = 15_000;

  public refetchQueries: any[] = [];
  public filterRequest: FilterRequestInput = {
    query: null,
    endDate: null,
    startDate: null,
    sortBy: 'Name',
    sortOrder: null,
    showDeleted: false,
    tournamentId: null,
    distance: null,
    divisions: null,
    tournamentStatuses: null,
    showPast: false,
    showOnlyMyTournaments: false,
  };
  // #region Helper methods

  public get oneRefCache() {
    return this.oneRefs;
  }

  public get allRefCache() {
    return this.allRefs;
  }

  public get queryRefCache() {
    return this.queryRefs;
  }

  public getItemFromRouteId(route?: ActivatedRoute): Observable<ReturnT> {
    let activeRoute = route || this.route;

    return activeRoute.params.pipe(
      switchMap((p) => {
        if (!!p['id'] && p['id'] !== 'new') return this.one({ id: p['id'] });
        else return EMPTY;
      }),
      takeUntilDestroyed(),
    );
  }

  // #endregion

  // #region CRUD methods

  /**
   * @function initGql is method for initializating operations based on provided operation names and opearation args
   * @argument names is config object for setuping operation names. The required field is oneName,
   * if you dont pass any other names, they will be created automaticaly based on oneName value.
   * The default name for allName will be plural of oneName, if plural is not correct, you hould provide allName by yourself
   */
  protected initGQL(config: {
    names: GQLOperationNames;
    args?: GQLOperationArgs;
  }) {
    this.setOperationNames(config.names);
    this.setOperationArgs(config.args);
    this.createOperations();
  }

  /**
   * @function all function should be used for fetching base list of items, its also implements pagination and filters
   * @argument slot - This is key for the caching and for storing queryRef in store.  Store name is allRefs. By default the slot key will be value of allNames.
   * @argument pageRequest - This argument is used for pagination
   * @argument useCache - This is flag for setup fetchPolicy. True = 'cache-firs' adn false is 'network-only'
   */
  public all(
    options: {
      slot?: string;
      pageRequest?: PageRequestInput;
      useCache?: boolean;

      filterRequest?: FilterRequestInput;
    } = {},
  ): Observable<ReturnT[]> | null {
    this.paginationLoading = true;
    let {
      slot = this.operationNames.allName!,
      pageRequest = this.pageRequest,
      useCache = true,
      filterRequest = this.filterRequest,
    } = options;

    let variables: any = {
      pageRequest: pageRequest,
      filterRequest: filterRequest,
    };

    if (!this.allRefs.has(slot)) {
      let ref = this.apollo.watchQuery<GqlListResponse<ReturnT>>({
        query: this.selectAllQuery,
        fetchPolicy: useCache ? 'cache-first' : 'network-only',
        variables: variables,
      });

      this.allRefs.set(slot, ref);

      if (slot === this.operationNames.allName) {
        this.refetchQueries.push({
          query: this.selectAllQuery,
          variables: variables,
        });
      }
    }

    return this.allRefs.get(slot)!.valueChanges.pipe(
      map((result) => {
        this.paginationLoading = false;
        return this.extractListResult(result);
      }),
      catchError((err) => {
        console.error(
          `ERROR on baseService.all() - query: ${this.operationNames.allName}`,
          err,
        );
        this.paginationLoading = false;

        throw err;
      }),
    );
  }

  public allListData() {
    if (!this.allListDataQuery) return [];

    return this.apollo
      .query({
        query: this.allListDataQuery!,
      })
      .pipe(
        map((result: any) => {
          if (!result || !result.data) return null;

          const keys = Object.keys(result.data);

          if (result.data && keys.length) {
            this.totalCount = result.data[keys[0]].totalCount || 0;
            return result.data[keys[0]].data;
          }
          return [];
        }),

        catchError((err) => {
          console.error(
            `ERROR on baseService.query(${this.operationNames.allName})`,
            err,
          );
          throw err;
        }),
      );
  }

  /**
   * @function one function should be used for fetching one item by id
   * @argument slot - This is key for the caching and for storing queryRef in store. Store name is oneRefs. By default the store key will be provided ID
   * @argument useCache - This is flag for setup fetchPolicy. True = 'cache-firs' adn false is 'network-only'
   */
  public one(options: {
    id: string;
    slot?: string | null;
    useCache?: boolean;
  }): Observable<ReturnT> {
    let { id, slot, useCache = true } = options;
    slot = slot || id;

    if (!this.oneRefs.has(slot)) {
      let ref = this.apollo.watchQuery<GqlSingleResponse<ReturnT>>({
        query: this.selectOneQuery,
        fetchPolicy: useCache ? 'cache-first' : 'network-only',
        variables: { id },
      });

      this.oneRefs.set(slot, ref);
    }

    return this.oneRefs.get(slot)!.valueChanges.pipe(
      map((result) => this.extractOneResult<ReturnT>(result)),
      catchError((err) => {
        console.error(
          `Error in baseService.one(${id}) - query: ${this.operationNames.oneName}`,
          err,
        );
        throw err.message;
      }),
    );
  }

  /**
   * @function mutate function should be used for generating custom mutation of some service.
   * @argument mutation - Mutation document node
   * @argument refetchQueries - This is array of queries tat should be refetched after mutatation execute successfully
   * @argument data - This argument provide variables for mutation
   */
  public mutate<T = ReturnT>(options: {
    mutation: DocumentNode;
    variables?: any;
    refetchQueries?: any[];
  }): Observable<T> {
    let { mutation, variables = null, refetchQueries = [] } = options;
    return this.apollo
      .mutate({
        mutation,
        variables,
        refetchQueries,
      })
      .pipe(
        first(),
        map((result: any) => this.extractOneResult<T>(result)),
        catchError((e) => {
          throw e.message;
        }),
      );
  }

  /**
   * @function query function should be used for generating custom queries of some service. It stor refs by default in queryRefs store.
   * @argument slot - This is key for the caching and for storing queryRef in store.
   * @argument useCache - This is flag for setup fetchPolicy. True = 'cache-firs' adn false is 'network-only'
   * @argument data - This argument provide variables for query
   */
  public query<T = ReturnT>(options: {
    query: DocumentNode;
    slot: string;
    data?: any;
    useCache?: boolean;
  }): Observable<T> {
    let { query, slot, data = null, useCache = true } = options;

    if (!this.queryRefs.has(slot)) {
      let ref = this.apollo.watchQuery<T>({
        query: query,
        fetchPolicy: useCache ? 'cache-first' : 'network-only',
        variables: data,
      });
      this.queryRefs.set(slot, ref);
    }

    return this.queryRefs.get(slot)!.valueChanges.pipe(
      map((e: any) => e),
      catchError((err) => {
        console.error(`ERROR on baseService.query() - query: ${slot}`, err);
        throw err.message;
      }),
    );
  }

  /**
   * @function create function should be used for creating new item. Unsubscripiton mechanism is supported, so you dont have to wory about unsubscribe.
   * @argument item - Input value for creating object
   * @argument update - This is flag for updating cache. We are using 2 approaches. True = cache will be updated automatically, and if the flag is false,
   * after creating object the query for fetching all itmes (query generated by ) will be refetched.
   */
  public create(item: InputT, update: boolean = false) {
    let createRef = this.apollo
      .mutate({
        mutation: this.createMutation,
        variables: { item },
        refetchQueries: !update ? [...this.refetchQueries] : [],
        update: (cache, { data }) => {
          if (!update) return;

          const queryData: any = cache.readQuery({
            query: this.selectAllQuery,
          });

          if (!queryData) return;

          let key = Object.keys(queryData)[0];
          let items = [...queryData[key].data];
          let totalCount = queryData[key]?.totalCount;
          items.pop();

          let createdObjKey = Object.keys(data as any)[0];
          let newValue = (data as any)[createdObjKey];

          let dataToWrite: any = {};
          dataToWrite[key] = {};
          dataToWrite[key]['totalCount'] = totalCount;
          dataToWrite[key]['data'] = [{ ...newValue }, ...items];

          cache.writeQuery<any>({
            query: this.selectAllQuery,
            data: {
              ...dataToWrite,
            },
          });
        },
      })
      .pipe(
        first(),
        map((result: any) => this.extractOneResult<ReturnT>(result)),
        tap(() => (this._itemLoading = false)),
        catchError((err) => {
          this._itemLoading = false;
          console.error(err);
          this.ui.errorSnack(err.message);
          throw err.message;
        }),
      );

    return defer(() => {
      this._itemLoading = true;
      return createRef;
    });
  }

  /**
   * @function update function should be used for creating new item. Unsubscripiton mechanism is supported, so you dont have to wory about unsubscribe.
   * @argument item - Input value for updating object
   * @description udpate method will update cache automaticaly based on __typename value and id of object that is stored in cache
   */
  public update(item: InputT) {
    let updateRef = this.apollo
      .mutate({
        mutation: this.updateMutation,
        variables: { item },
      })
      .pipe(
        first(),
        map((result: any) => this.extractOneResult<ReturnT>(result)),
        tap(() => (this._itemLoading = false)),
        catchError((err) => {
          console.error(err);
          this.ui.errorSnack(err.message);
          this._itemLoading = false;
          throw err.message;
        }),
      );

    return defer(() => {
      this._itemLoading = true;
      return updateRef;
    });
  }

  /**
   * @function delete function should be used for deleteing item. Unsubscripiton mechanism is supported, so you dont have to wory about unsubscribe.
   * @argument id - Id of target object
   * @description method will update cache automaticaly based on __typename value and id of object that is stored in cache.
   * It will update only deleted field
   */
  public delete(id: string, refetchAllQueries: boolean = false) {
    let deleteRef = this.apollo
      .mutate({
        mutation: this.deleteMutation,
        refetchQueries: refetchAllQueries ? this.refetchQueries : [],
        variables: { id },
      })
      .pipe(
        first(),
        tap(() => (this._itemLoading = false)),
        map((result: any) => this.extractOneResult(result)),
        catchError((err) => {
          console.error(err);
          this.ui.errorSnack(err.message);
          this._itemLoading = false;
          throw err.message;
        }),
      );

    return defer(() => {
      this._itemLoading = true;
      return deleteRef;
    });
  }

  /**
   * @function delete function should be used for restore of deleted item. Unsubscripiton mechanism is supported, so you dont have to wory about unsubscribe.
   * @argument id - Id of target object
   * @description method will update cache automaticaly based on __typename value and id of object that is stored in cache.
   * It will update only deleted field
   */
  public restore(id: string) {
    return this.apollo
      .mutate({
        mutation: this.restoreMutation,
        variables: { id },
      })
      .pipe(
        first(),
        map((result: any) => this.extractOneResult(result)),
        catchError((err) => {
          throw err.message;
        }),
      );
  }
  //#endregion

  // #region refetch methods

  override fetchMoreData(options?: {
    slot?: string;
    data?: any;
  }): void | Promise<ApolloQueryResult<ReturnT>> {
    console.log('fetch more data BAse service');

    let slot = options?.slot || this.operationNames.allName!;
    let data = options?.data;

    this.paginationLoading = true;

    if (!this.allRefs.get(slot)) {
      console.warn(`No query initiated in \'ALL STORE\' with slot: ${slot}`);
    }

    this.allRefs
      .get(slot)
      ?.fetchMore({
        variables: { pageRequest: this.pageRequest, ...data },
      })
      ?.catch(() => (this.paginationLoading = false))
      ?.then(() => (this.paginationLoading = false));
  }

  //Refetch custom query stored in ALL STORE
  public refetchAll(options: { slot?: string; data?: any } = {}) {
    let { slot = this.operationNames.allName!, data } = options;
    let ref = this.allRefs.get(slot);
    if (!ref) {
      console.warn(`There is no data in \'ALL STORE\' with slot: ${slot}`);
      return;
    }

    this.paginationLoading = true;
    return ref
      ?.refetch(data)
      ?.catch(() => (this.paginationLoading = false))
      ?.then(() => (this.paginationLoading = false));
  }

  //Refetch custom query stored in ONE STORE
  public refetchOne(slot: string, data?: any) {
    let ref = this.oneRefs.get(slot);
    if (!ref)
      console.warn(`There is no data in \'ONE STORE\' with slot: ${slot}`);
    ref?.refetch(data);
  }

  //Refetch custom query stored in QUERY STORE
  public refetchQuery(slot: string, data?: any) {
    let ref = this.queryRefs.get(slot);
    if (!ref)
      console.warn(`There is no data in \'QUERY STORE\' with slot: ${slot}`);
    ref?.refetch(data);
  }

  //  #endregion

  // #region evict cache methods

  evictAllWqCache(slot?: string) {
    slot = slot || this.operationNames.allName!;
    if (!this.allRefs.has(slot)) return;
    this.allRefs.delete(slot);
    this.apollo.client.cache.evict({ fieldName: slot });
  }

  evictOneWqCache(id: string) {
    if (!this.oneRefs.has(id)) return;
    this.oneRefs.delete(id);
    this.apollo.client.cache.evict({ fieldName: id });
  }

  evictQueryWqCache(slot: string) {
    if (!this.queryRefs.has(slot)) return;
    this.queryRefs.delete(slot);
    this.apollo.client.cache.evict({ fieldName: slot });
  }
  // #endregion

  // #region private methods
  private camelNodeName(name: string) {
    if (!name || name.length == 0) return '';
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  private setOperationNames(names: GQLOperationNames) {
    let oneName = this.camelNodeName(names.oneName);

    this.operationNames.oneName = names.oneName;
    this.operationNames.allName = names?.allName || `${names.oneName}s`;
    this.operationNames.createName = names.createName || `create${oneName}`;
    this.operationNames.updateName = names.updateName || `update${oneName}`;
    this.operationNames.deleteName = names.deleteName || `delete${oneName}`;
    this.operationNames.restoreName = names.restoreName || `restore${oneName}`;
  }

  private setOperationArgs(args?: GQLOperationArgs) {
    let idParams = '$id: ID!';
    let idArgs = 'id: $id';

    let createParams = `$item: ${this.camelNodeName(this.operationNames.oneName)}Input!`;
    let createArgs = 'item: $item';

    //Get by id
    this.operationArguments.oneParams = args?.oneParams || idParams;
    this.operationArguments.oneArgs = args?.oneArgs || idArgs;

    //get list of items
    this.operationArguments.allParams =
      args?.allParams || '$pageRequest: PageRequestInput!';
    this.operationArguments.allArgs =
      args?.allArgs || 'pageRequest: $pageRequest';

    this.operationArguments.filterParams =
      args?.filterParams || '$filterRequest:FilterRequestInput!';
    this.operationArguments.filterArgs =
      args?.filterArgs || 'filterRequest: $filterRequest';

    //create item
    this.operationArguments.createParams = args?.createParams || createParams;
    this.operationArguments.createArgs = args?.createArgs || createArgs;

    //update item
    this.operationArguments.updateParams = args?.updateParams || createParams;
    this.operationArguments.updateArgs = args?.updateArgs || createArgs;

    //delete item
    this.operationArguments.deleteParams = args?.deleteParams || idParams;
    this.operationArguments.deleteArgs = args?.deleteArgs || idArgs;

    //restore item
    this.operationArguments.restoreParams = args?.restoreParams || idParams;
    this.operationArguments.restoreArgs = args?.restoreArgs || idArgs;
  }

  private createOperations() {
    const oneName = this.camelNodeName(this.operationNames.oneName);

    //Fetch list of items
    this.selectAllQuery = gql`
      query ${this.operationNames.allName}(${this.operationArguments.allParams} ${this.operationArguments.filterParams}){
        ${this.operationNames.allName}(${this.operationArguments.allArgs} ${this.operationArguments.filterArgs}){
          totalCount
          data{
            ...SelectAllFields${oneName}
          }
        }
      }
      ${this.selectAllFields}
    `;

    // Fetch all data for dropdown menues
    if (!!this.selectAllListData)
      this.allListDataQuery = gql`
      query ${this.operationNames.allName}{
        ${this.operationNames.allName}(pageRequest:{offset: 0, limit: ${this.maxLimit}}){
          totalCount
          data{
            ...SelectAllListData${oneName}
          }
        }
      }
      ${this.selectAllListData}
    `;

    //Fetch one item
    this.selectOneQuery = gql`
      query ${this.operationNames.oneName}(${this.operationArguments.oneParams}){
        ${this.operationNames.oneName}(${this.operationArguments.oneArgs}){
          ...SelectOneFields${oneName}
        }
      }
      ${this.selectOneFields}
    `;

    //Create new item
    this.createMutation = gql`
      mutation ${this.operationNames.createName}(${this.operationArguments.createParams}){
        ${this.operationNames.createName}(${this.operationArguments.createArgs}){
          ...SelectOneFields${oneName}
        }
      }
      ${this.selectOneFields}
    `;

    //Update an existing item
    this.updateMutation = gql`
      mutation ${this.operationNames.updateName}(${this.operationArguments.updateParams}){
        ${this.operationNames.updateName}(${this.operationArguments.updateArgs}){
          ...SelectOneFields${oneName}
        }
      }
      ${this.selectOneFields}
    `;

    //Delete item
    this.deleteMutation = gql`
      mutation ${this.operationNames.deleteName}(${this.operationArguments.deleteParams}){
        ${this.operationNames.deleteName}(${this.operationArguments.deleteArgs}){
          ...DeleteRestoreFields${oneName}
        }
      }
      ${this.deleteRestoreFields}
    `;

    //Restore item
    this.restoreMutation = gql`
        mutation ${this.operationNames.restoreName}(${this.operationArguments.restoreParams}){
          ${this.operationNames.restoreName}(${this.operationArguments.restoreArgs}){
            ...DeleteRestoreFields${oneName}
          }
        }
        ${this.deleteRestoreFields}
      `;
  }

  private extractListResult(
    result: ApolloQueryResult<GqlListResponse<ReturnT>>,
  ): ReturnT[] {
    if (!result || !result.data) return [];

    const keys = Object.keys(result.data);
    if (!keys.length) return [];

    const res = result.data[keys[0]];
    this.updateTotalCount(res.totalCount || 0);
    return res?.data ?? [];
  }

  private extractOneResult<T>(
    result: ApolloQueryResult<GqlSingleResponse<T>>,
  ): T {
    if (!result?.data) {
      return {} as T;
    }

    const keys = Object.keys(result.data);
    if (!keys.length) {
      return {} as T;
    }

    const operationName = keys[0];
    const item = result.data[operationName];
    return item ?? ({} as T);
  }
  // #endregion
}
