import { Injectable } from '@angular/core';
import type { ApolloQueryResult } from '@apollo/client/core';
import { PageRequestInput } from '@gqlSchema';

@Injectable({
  providedIn: 'root',
})
export abstract class PaginatorService {
  public defaultPageSize = 10;

  public pageRequest: PageRequestInput = {
    offset: 0,
    limit: this.defaultPageSize,
  };

  public pageSizes = [5, 10, 25, 50];
  public pageIndex: number = 0;
  public totalCount: number = 0;
  public paginationLoading: boolean = false;

  abstract fetchMoreData(data: any): void | Promise<ApolloQueryResult<any>>;

  public get hasMoreData() {
    return this.pageRequest.offset + this.pageRequest.limit < this.totalCount;
  }

  public updateTotalCount(count: number): void {
    this.totalCount = count;
  }

  public setPagination(
    offset: number = 0,
    limit: number = this.defaultPageSize,
  ) {
    this.pageRequest.offset = offset;
    this.pageRequest.limit = limit;
  }

  public resetPagination(
    offset: number = 0,
    limit: number = this.defaultPageSize,
  ) {
    this.setPagination(offset, limit);
    this.pageIndex = 0;
    this.updateTotalCount(0);
  }

  public getNextPage(data: any = null) {
    if (!this.hasMoreData) return;

    this.paginationLoading = true;
    this.pageRequest.offset = this.pageRequest.limit * (this.pageIndex + 1);
    this.pageIndex++;
    this.fetchMoreData(data)?.finally(() => (this.paginationLoading = false));
  }

  public getPreviousPage(data: any = null) {
    if (!this.pageIndex) return;

    this.pageRequest.offset = this.pageRequest.limit * (this.pageIndex - 1);
    this.pageIndex--;
    this.paginationLoading = true;
    this.fetchMoreData(data)?.finally(() => (this.paginationLoading = false));
  }
}
